<template>
  <div>
    <div v-show="visible">
      <top-bar :title="!submitData.id?'新增巡查':edit?'修改巡查':'查看巡查'" :left="true"></top-bar>
      <!-- 巡查地点 -->
      <van-popup v-model="addrShow" position="bottom"  >
        <van-picker title="请选择" show-toolbar :columns="selectData.addrList" value-key="label"
                    @confirm="addrConfirm" @cancel="addrShow = false"/>
      </van-popup>
      <!-- 建筑类别 -->
      <van-popup v-model="buildingTypeShow" position="bottom" >
        <van-picker title="请选择" show-toolbar :columns="selectData.buildingTypeList" value-key="label"
                    @confirm="buildingTypeConfirm"  @cancel="buildingTypeShow = false"/>
      </van-popup>
      <!-- 选择房屋 -->
      <van-popup v-model="buildingShow" position="bottom" >
        <van-cascader v-model="submitData.building" title="请选择" :options="selectData.buildingList"
                      @close="buildingShow = false" @finish="buildingFinish" :field-names="fieldNames"/>
      </van-popup>
      <!-- 公共楼幢、工业楼幢 -->
      <van-popup v-model="publicBuildingShow" position="bottom" >
        <van-picker title="请选择" show-toolbar :columns="selectData.publicBuildingList" value-key="name"
                    @confirm="publicBuildingConfirm"  @cancel="publicBuildingShow = false"/>
      </van-popup>
      <!-- 所在社区 -->
      <van-popup v-model="communityShow" position="bottom" >
        <van-picker title="请选择" show-toolbar :columns="selectData.communityList" value-key="label"
                    @confirm="communityConfirm" @cancel="communityShow = false"/>
      </van-popup>
      <!-- 所在小区 -->
      <van-popup v-model="subareaShow" position="bottom" >
        <van-notice-bar v-if="selectMsg.communityStr == '请选择'">请先选择所在社区获取所在小区列表。</van-notice-bar>
        <van-picker title="请选择" show-toolbar :columns="selectData.subareaList" value-key="name"
                    @confirm="subareaConfirm" @cancel="subareaShow = false"/>
      </van-popup>
      <!-- 巡查类别 -->
      <van-popup v-model="inspectionTypeShow" position="bottom" >
        <van-cascader v-model="submitData.inspectionType" title="请选择" :options="selectData.inspectionTypeList"
                      @close="inspectionTypeShow = false" @finish="inspectionTypeFinish" :field-names="fieldNames"/>
      </van-popup>
      <!-- 巡查时间 -->
      <van-popup v-model="inspectionTimeShow" position="bottom" >
        <van-datetime-picker v-model="submitData.inspectionTime" type="datetime" title="请选择" :formatter="formatDate"
                              @cancel="inspectionTimeShow = false" @confirm="timeConfirm"/>
      </van-popup>

      <van-popup v-model="sourceTypeShow" position="bottom" >
        <van-picker title="请选择" show-toolbar :columns="selectData.sourceTypeList" value-key="label"
                    @confirm="sourceTypeConfirm" @cancel="sourceTypeShow = false"/>
      </van-popup>


      <!-- 问题类型 -->
      <van-popup v-model="problemTypeShow" position="bottom" >
        <van-cascader title="请选择" :options="selectData.problemList"
                      @close="problemTypeShow = false" @finish="problemTypeFinish" :field-names="fieldNames"/>
      </van-popup>

      <van-tabs v-model="activeName" :class="submitData.id ? '' : 'tab'">
        <van-tab title="巡查详情" :name="'tab1'" class="info">
          <van-cell-group>
            <van-cell>
              <template #title>
                <div class="blue-box"></div>
                <span class="custom-title">巡查信息</span>
              </template>
            </van-cell>
            <van-cell title="巡查来源" :value="selectMsg.sourceTypeStr" @click="sourceTypeShow = edit"
                      :value-class="{'value-common':selectMsg.sourceTypeStr=='请选择'}" :is-link="edit" />
            <van-cell title="巡查地点" :value="selectMsg.addrTypeStr" @click="addrShow = edit" :clickable="edit"
                      :value-class="{'value-common':selectMsg.addrTypeStr=='请选择'}" :is-link="edit" />
            <van-cell title="所在社区" :value="selectMsg.communityStr" @click="communityShow = edit" :clickable="edit"
                      :value-class="{'value-common':selectMsg.communityStr=='请选择'}" :is-link="edit" />
            <van-cell title="建筑类别" :value="selectMsg.buildingTypeStr" @click="buildingTypeShow = edit" :clickable="edit"
                      v-if="submitData.addrType==1&&edit&&!isTasks" :value-class="{'value-common':selectMsg.buildingTypeStr=='请选择'}" :is-link="edit" />
            <van-cell title="住宅房屋" :value="selectMsg.buildingStr" @click="buildingShow = edit" :clickable="edit"
                      v-if="submitData.addrType==1&&submitData.buildingType==1&&edit" :value-class="{'value-common':selectMsg.buildingStr=='请选择'}" :is-link="edit" />
            <van-cell :title="submitData.buildingType==2?'公共楼幢':'工业楼幢'" :value="selectMsg.publicBuildingStr" @click="publicBuildingShow = edit" :clickable="edit"
                      v-if="(submitData.buildingType==2||submitData.buildingType==3) && submitData.addrType==1" :value-class="{'value-common':selectMsg.publicBuildingStr=='请选择'}" :is-link="edit" />
            <van-cell :title="edit?'选择场所':'场所'" :value="selectMsg.placeStr" @click="selectPlace" v-if="submitData.addrType==2" :clickable="edit"
                      :value-class="{'value-common':selectMsg.placeStr=='请选择'}" :is-link="edit" />
            <van-cell title="所在小区" :value="selectMsg.subareaStr" @click="subareaShow = edit" v-if="submitData.addrType==3" :clickable="edit"
                      :value-class="{'value-common':selectMsg.subareaStr=='请选择'}" :is-link="edit" />
            <van-cell title="详细地址">
              <van-field clearable clear-trigger="always" v-model="submitData.address" placeholder="请输入" :readonly="!edit"/>
            </van-cell>
            <van-cell title="出租房隔间" v-if="submitData.buildingType==1">
              <van-field clearable clear-trigger="always" v-model="submitData.rentHouseCount" :readonly="!edit"/>
            </van-cell>
            <van-cell title="巡查类别" :value="selectMsg.inspectionTypeStr" @click="inspectionTypeShow = edit"
                      :value-class="{'value-common':selectMsg.inspectionTypeStr=='请选择'}" :is-link="edit" />
            <van-cell title="巡查时间" :value="selectMsg.inspectionTime" @click="inspectionTimeShow = edit"
                      :value-class="{'value-common':selectMsg.inspectionTime=='请选择'}" :is-link="edit" />
            <van-field  clearable clear-trigger="always" v-model="submitData.description" rows="3" class="left" :border="false" autosize type="textarea" placeholder="请输入巡查描述" :readonly="!edit"/>
            <van-row v-if="edit||fileList.length!==0">
              <van-col :span="24">
                <van-uploader v-model="fileList" multiple @delete="uploaddel" :after-read="uploadRead" :deletable="edit"
                              :show-upload="edit" :before-read="beforeUpload"/>
              </van-col>
            </van-row>
          </van-cell-group>
          <van-cell-group v-if="submitData.buildType!=2 && isOpen && !submitData.id" class="info-second">
            <van-cell title="未处理问题数" :value="problemIds && problemIds.length > 0 ? problemIds.length + '个' : 0 + '个'" value-class="check" @click="toProblem"/>
          </van-cell-group>
          <van-cell-group v-if="isOpen && !submitData.id" class="info-second">
            <van-cell title="是否需要上报问题" center>
              <van-switch v-model="problemSubmit" size="24px" @change="problemSubmitShow" :disabled="!!submitData.id"/>
            </van-cell>
          </van-cell-group>

          <template v-if="problemSubmit">
            <van-cell-group v-for="(item, index) in submitData.problemList" :key="index" class="info-second">
              <van-cell :title="'问题'+(index+1)" v-if="!submitData.id">
                <van-image :src="require('@/assets/img/problem_add.png')" height="25" width="25" style="margin-right: 10px" @click="addProblem"/>
                <van-image :src="require('@/assets/img/problem_delete.png')" height="25" width="25" style="margin-right: 10px" @click="deleteProblem(index)"/>
                <van-image :src="require(`@/assets/img/problem_${problemShow?'shrink':'open'}.png`)" height="25" width="25" @click="problemShow=!problemShow"/>
              </van-cell>
              <div v-show="problemShow">
                <van-cell title="处理状态" v-if="submitData.id">
                  <van-field clearable clear-trigger="always" v-model="item.status" readonly input-align="right"/>
                </van-cell>
                <van-cell title="问题类型" :value="item.typeTitle" @click="!!submitData.id ? false : checkProblemType(index)"
                          :value-class="{'value-common':item.typeTitle=='请选择'}" :is-link="!submitData.id" />
                <van-field
                    :readonly="!!submitData.id"
                    clearable
                    clear-trigger="always"
                    :border="false"
                    class="left"
                    v-model="item.des"
                    rows="3"
                    autosize
                    type="textarea"
                    placeholder="请输入问题描述"
                    input-align="left"
                />
                <van-row style="padding: 0 5px;">
                  <van-col :span="24">
                    <upload-file
                        :file-list="item.files"
                        :del-btn-show="!submitData.id"
                        :upload-btn-show="!submitData.id"
                        @filesUpload="filesUpload($event, index)"
                        @delFile="delProblemFile"
                        :upload-path="'files/problem/temporary'"
                        :file-type="'all'"/>
                    <!--                <van-uploader v-model="item.files" @delete="uploaddelProblem" :name="index"-->
                    <!--                              :after-read="uploadReadProblem" :before-read="beforeUpload" :disabled="!isEdit"/>-->
                  </van-col>
                </van-row>
              </div>
              <van-cell title="是否关联此次巡查" center>
                <van-switch v-model="item.isRelation" size="24px" :disabled="!!submitData.id"/>
              </van-cell>
            </van-cell-group>
          </template>

          <van-row class="btns" v-if="submitData.id ? (createOrgId == Number($orgId) ? true : false) : edit">
            <van-col :span="24" v-if="edit">
              <van-button type="info" size="large" :loading="loading" round @click="submit">完成</van-button>
            </van-col>
            <van-row gutter="10" v-else>
              <van-col :span="12">
                <van-button plain type="danger" size="large" round @click="deleteHandle" style="background-color:transparent">删除</van-button>
              </van-col>
              <van-col :span="12">
                <van-button type="info" size="large" round @click="edit=true">编辑</van-button>
              </van-col>
            </van-row>
          </van-row>
        </van-tab>

        <!--   关联问题详情     -->
        <van-tab title="关联信息" :name="'tab2'">
          <div class="list-info">
            <div class="cont">
              <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList"
                        v-if="dataList.length > 0" offset="10">
                <div class="totalCount">共有&nbsp;<span style="color:#387FF5">{{ dataList.length }}</span>&nbsp;条数据</div>
                <div class="list-item" v-for="item in dataList" :key="item.id"
                     @click="goInfo(item.id,item.status,item.streetIsOpen)">
                  <div class="title">
                    <div class="title-left">
                      {{ item.name + ' | ' + item.createDate }}
                    </div>
                    <div class="title-right" :style="{color:item.status==0||item.status==10?'#ED4747':item.status==20?'#23D87B'
                :item.status==30?'#387FF5':'#0AB9B3'}">
                      {{ item.statusStr }}
                    </div>
                  </div>
                  <div class="content">
                    <van-image :src="item.url?item.url:require('@/assets/img/flower.png')" class="content-img">
                      <template slot="error">
                        <img :src="require('@/assets/img/flower.png')" alt="" class="content-img">
                      </template>
                      <template slot="loading">
                        <img :src="require('@/assets/img/flower.png')" alt="" class="content-img">
                      </template>
                    </van-image>
                    <div class="content-text">
                      <div class="top-text">{{ item.houseFullName }} <span
                          style="color:#387FF5;font-size:14px">({{ item.typeStr }})</span></div>
                      <div class="bottom-text">{{ item.content }}</div>
                    </div>
                    <van-icon name="arrow" color="#666" class="content-icon"/>
                  </div>
                </div>
              </van-list>
              <van-image v-else :src="require('@/assets/img/empty.png')" style="margin-left:10%;width:80%;"></van-image>
            </div>
          </div>
        </van-tab>
      </van-tabs>
    </div>
    <place-list v-if="placeListVisible" @checkPlace="checkPlace" :org="submitData.community"></place-list>
  </div>
</template>

<script>
import topBar from '../../components/topBar/topBar.vue'
import {beforeFileUpload, formatterDateTimeWithOutSec} from "@/utils/utils"
import {getImageStream} from '../../utils/index'
import placeList from "@/components/placeList"
import { getBelongCommunity} from '@/utils/common'
import {mapMutations, mapState} from "vuex";
import uploadFile from '@/components/upload/uploadFile'

export default {
  name: 'patrol-add',
  data() {
    return {
      activeName: 'tab1',
      finished: false,
      dataList: [],
      i: '',
      createOrgId: 0,
      visible: true,
      edit: false,
      file: {},
      detailData: {},
      fieldNames: {
        text: 'label',
        value: 'value',
        children: 'children',
      },
      loading: false,
      placeListVisible: false,
      addrShow: false,
      buildingTypeShow: false,
      buildingShow: false,
      publicBuildingShow: false,
      subareaShow: false,
      inspectionTypeShow: false,
      inspectionTimeShow: false,
      sourceTypeShow: false,
      problemShow: true,
      problemSubmit: false,
      problemTypeShow: false,
      isTasks: false,
      communityShow: false,
      isOpen: false, //是否有问题反馈
      problemIds: [], // 未解决问题数
      problemInfoIds : '',
      selectMsg: {
        addrTypeStr: '请选择',
        buildingTypeStr: '请选择',
        buildingStr: '请选择',
        publicBuildingStr: '请选择',
        placeStr: '请选择',
        subareaStr: '请选择',
        inspectionTypeStr: '请选择',
        inspectionTime: '请选择',
        communityStr: '请选择',
        sourceTypeStr: '社区自查'
      },
      submitData: {
        id: '',
        addrType: '',
        addrId: '',
        buildingType: '',
        rentHouseCount: 0,
        buildingId: '',
        subarea: '',
        address: '',
        detailedAddressValue: [],
        inspectionType: '',
        inspectionTime: '',
        description: '',
        building: '',
        problemList: [],
        newFileMessages: [],
        deleteFiles: [],
        community: '',
        sourceType: 2
      },
      selectData: {
        addrList: [{label: "楼幢", value: "1"}, {label: "场所", value: "2"}, {label: "公共区域", value: "3"}],
        buildingTypeList: [{label: "住宅楼幢", value: "1"}, {label: "公共楼幢", value: "2"}, {label: "工业楼幢", value: "3"}],
        buildingList: [],
        publicBuildingList: [],
        subareaList: [],
        inspectionTypeList: [],
        problemList: [],
        communityList: [],
        sourceTypeList: [{label: "上级督查", value: 1}, {label: "社区自查", value: 2}]
      },
      fileList: [],

    }
  },
  computed: {...mapState(['problemVisitIds'])},
  components: {
    topBar,
    placeList,
    uploadFile
  },
  watch: {
    'selectData.buildingList': {
      handler(newarr, oldarr) {
        if (this.$route.query.ids) {
          if (newarr !== []) {
            let selectedOptions = []
            let ids = this.$route.query.ids.split('-')
            ids.map(item => {
              if (item) {
                return item
              }
            })
            this.getIdsList(ids, 0, this.selectData.buildingList, selectedOptions)
            let obj = {
              selectedOptions,
              value: selectedOptions[selectedOptions.length - 1].value
            }
            this.buildingFinish(obj)
          }
        }
      }
    },
    'problemVisitIds': {
      handler(newarr, oldarr) {
        if (this.problemVisitIds) {
          this.getPatrolProblem()
        }
      }
    }
  },
  methods: {
    ...mapMutations,
    // 查看关联问题详情
    goInfo(id, status, streetIsOpen) {
      let data = {'id': id, 'status': status, 'streetIsOpen': streetIsOpen}
      this.$store.commit('setProblemData', data)
      this.$router.push('/problem-info')
    },
    // 删除图片
    uploaddel(file, detail) {
      let i = detail.index
      this.submitData.newFileMessages.splice(i, 1)
      if (file.id !== 0) {
        this.submitData.deleteFiles.push(file.id)
      }
    },
    delFile(id, index) {
      this.submitData.deleteFiles.push(id)
    },
    filesUpload(files, index) {
      this.submitData.problemList[index].files = files
      this.submitData.problemList[index].problemUrls = files
    },
    delProblemFile(id, index) {
      this.typeList[index].deleteFiles.push(id)
    },
    // 文件上传前的回调
    beforeUpload (file) {
      this.$toast.loading({
        message: '上传中...',
        forbidClick: true,
        duration: 0,
        overlay: true
      })
      return beforeFileUpload(file)
    },
    // 上传图片
    uploadRead(file) {
      if (!Array.isArray(file)) {
        file = [file]
      }
      file.forEach((item, index2) => {
        let formdata = new FormData();
        formdata.append("file", item.file)
        formdata.append("path", 'files/inspectionPicture/temporary')
        this.$http({
          url: this.$http.adornUrl('/wxapp/file/upload'),
          method: 'post',
          data: formdata
        }).then(({data}) => {
          if (index2 === file.length - 1) {
            this.$toast.clear()
          }
          if (data.code == 0) {
            let fileMessage = data.fileMessage
            fileMessage['uid'] = this.fileList.length != 0 ? this.fileList.length : 0
            this.submitData.newFileMessages.push(fileMessage)
            return true
          } else {
            this.$toast.fail(data.msg)
          }
        })
      })
    },
    // 删除问题图片
    uploaddelProblem(file, detail) {
      let i = detail.index
      this.submitData.problemList[detail.name].files.splice(i, 1)
      this.submitData.problemList[detail.name].problemUrls = file.filter((n) => n.uid !== file.uid)
      this.submitData.problemList[detail.name].files = this.submitData.problemList[detail.name].files.filter((n) => n.newFileName !== file.fileMessage.newFileName)
    },
    // 上传问题图片
    uploadReadProblem(file, detail) {
      this.$toast.loading({
        message: '上传中...',
        forbidClick: true,
        duration: 0,
        overlay: true
      })
      if (!Array.isArray(file)) {
        file = [file]
      }
      file.forEach((item, index2) => {
        let formdata = new FormData();
        formdata.append("file", item.file)
        formdata.append("path", 'files/problem/temporary')
        this.$http({
          url: this.$http.adornUrl('/wxapp/file/upload'),
          method: 'post',
          data: formdata
        }).then(({data}) => {
          if (index2 === file.length - 1) {
            this.$toast.clear()
          }
          if (data.code == 0) {
            let fileMessage = data.fileMessage
            fileMessage['uid'] = this.submitData.problemList[detail.name].files.length != 0 ? this.submitData.problemList[detail.name].files.length : 0
            this.submitData.problemList[detail.name].files.push(fileMessage)
            this.submitData.problemList[detail.name].problemUrls.push(fileMessage)
            return true
          } else {
            this.$toast.fail(data.msg)
          }
        })
      })
    },
    // 初始化
    init() {
      getBelongCommunity(this.$orgId, -1, (communityList) => {
        this.selectData.communityList = communityList
      })
      this.getDictTypeList('inspectionType')
      this.getDictTypeList('problemType')
      let nowDate = new Date()
      this.maxDate = nowDate
      this.selectMsg.inspectionTime = formatterDateTimeWithOutSec(nowDate)
      this.submitData.inspectionTime = nowDate
      if (this.submitData.id) {
        this.edit = false
        this.getInfo()
      } else {
        this.edit = true
      }
    },
    // 是否有问题反馈
    getHasProblem() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/application/getAppIsOpen'),
        method: 'get',
        params: this.$http.adornParams({
          code: 'sjgl_wtjl',
          orgId: this.submitData.community
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.isOpen = data.isOpen
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    getPatrolProblem(){
      this.$http({
        url: this.$http.adornUrl('/wxapp/inspection/getInspectionProblemIds'),
        method: 'post',
        data: {
          //巡查地点id
          addrType: this.submitData.addrType,
          detailedAddress: this.submitData.detailedAddressValue,
          address: this.submitData.address,
          buildingType: this.submitData.buildingType,
          //小区
          subarea: this.submitData.subarea,
          //楼幢id
          buildingId: this.submitData.buildingId,
          addrId: this.submitData.buildingType == 1 || this.submitData.addrType == 2 ? this.submitData.addrId : undefined
        }
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.problemIds = data.list
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    // 获取房屋列表
    getBuildingList(orgId) {
      this.$http({
        url: this.$http.adornUrl('/wxapp/building/house/liveWhichTreeByDepth'),
        method: 'get',
        params: this.$http.adornParams({
          id: orgId,
          depth: 0
        }, false)
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.selectData.buildingList = data.liveWhichTree
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    // 获取楼幢列表
    getPublicBuildingList(buildingType, orgId) {
      this.$http({
        url: this.$http.adornUrl('/wxapp/building/info/list'),
        method: 'get',
        params: this.$http.adornParams({
          page: 1,
          limit: -1,
          orgId: orgId,
          buildingType: buildingType
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.selectData.publicBuildingList = data.page.list
          this.selectData.publicBuildingList.map(item => item.name = item.name + '幢')
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    // 获取小区列表
    getSubareaList(community) {
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/org/subAreaList'),
        method: 'post',
        params: this.$http.adornParams({
          communityId: community
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.selectData.subareaList = data.subAreaList
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    // 获取字典
    getDictTypeList(code) {
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/dict/listDictByCode'),
        method: 'post',
        params: this.$http.adornParams({
          code: code,
          orgId: this.$orgId,
          astrictValue: code === 'problemType' ? 1 : '',
          value: code === 'problemType' ? null : ''
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          if (code === 'inspectionType') {
            this.selectData.inspectionTypeList = data.dicts
          } else if (code === 'problemType') {
            this.selectData.problemList = data.dicts
          }
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    // 获取详情
    getInfo() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/inspection/info'),
        method: 'post',
        params: this.$http.adornParams({
          id: this.submitData.id
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.submitData.addrType = data.commonAppInspection.addrType
          this.submitData.buildingId = data.commonAppInspection.buildingId
          this.submitData.buildingType = data.commonAppInspection.buildingType
          this.selectMsg.buildingStr = data.commonAppInspection.address
          this.submitData.address = data.commonAppInspection.address
          this.selectMsg.placeStr = data.commonAppInspection.address
          this.submitData.rentHouseCount = data.commonAppInspection.room
          this.selectMsg.inspectionTypeStr = data.commonAppInspection.inspectionTypeStr
          this.submitData.inspectionType = data.commonAppInspection.inspectionType
          this.selectMsg.inspectionTime = data.commonAppInspection.inspectionTime
          this.submitData.inspectionTime = data.commonAppInspection.inspectionTime
          this.submitData.description = data.commonAppInspection.inspectionDes
          this.selectMsg.publicBuildingStr = data.commonAppInspection.address
          this.selectMsg.subareaStr = data.commonAppInspection.subareaName
          this.submitData.subarea = data.commonAppInspection.subarea
          this.selectMsg.communityStr = data.commonAppInspection.communityName
          this.submitData.community = data.commonAppInspection.community
          this.createOrgId = data.commonAppInspection.createOrgId
          this.submitData.sourceType = data.commonAppInspection.sourceType
          this.selectMsg.sourceTypeStr = data.commonAppInspection.sourceType == 1 ? '上级督查' : '社区自查'
          if (data.commonAppInspection.problemIds) {
            this.problemInfoIds = data.commonAppInspection.problemIds
            this.dataList = data.commonAppInspection.problems
          }
          this.finished = true
          if (this.submitData.community && this.edit == false) {
            let community = this.submitData.community
            this.getSubareaList(community)
          }
          let files = data.commonAppInspection.filesPath
          let urls = []
          for (let i in files) {
            let file = files[i]
            let u
            u = getImageStream(files[i].filePath)
            let url = {
              uid: urls.length,
              id: file.id,
              status: 'done',//uploading上传中，done上传完成，error上传失败
              fileType: file.type,
              url: u,
              realUrl: file.filePath.replace(/\\/g, "/")
            }
            urls.push(url);
          }
          this.fileList = urls
          if (data.commonAppInspection.addrType == 1) {
            this.selectMsg.addrTypeStr = '楼幢'
          } else if (data.commonAppInspection.addrType == 2) {
            this.selectMsg.addrTypeStr = '场所'
          } else if (data.commonAppInspection.addrType == 3) {
            this.selectMsg.addrTypeStr = '公共区域'
          }
          if (data.commonAppInspection.buildingType) {
            if (data.commonAppInspection.buildingType == 1) {
              this.selectMsg.buildingTypeStr = '住宅楼幢'
            } else if (data.commonAppInspection.buildingType == 2) {
              this.selectMsg.buildingTypeStr = '公共楼幢'
            } else if (data.commonAppInspection.buildingType == 3) {
              this.selectMsg.buildingTypeStr = '工业楼幢'
            }
          }

        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    deleteHandle() {
      this.$dialog.confirm({
        title: '确定删除吗？'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/wxapp/inspection/delete'),
          method: 'post',
          params: this.$http.adornParams({
            id: parseInt(this.submitData.id)
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            localStorage.setItem('del', 1)
            this.$toast.success({
              message: '删除成功',
              duration: 3000,
              onOpened: () => {
                setTimeout(() => {
                  this.$router.go(-1)
                }, 1500)
              }
            })
          } else {
            this.$toast.fail(data.msg)
          }
        })
      })
    },
    // 提交
    submit() {
      if (this.submitData.addrType === '') {
        return this.$toast.fail('失败:请选择巡查地点');
      }
      if (this.submitData.address === '') {
        return this.$toast.fail('失败:详细地址不能为空');
      }
      if (this.submitData.inspectionType === '') {
        return this.$toast.fail('失败:巡查类别不能为空');
      }
      if (this.submitData.inspectionTime === '') {
        return this.$toast.fail('失败:巡查时间不能为空');
      }
      if (this.submitData.description === '') {
        return this.$toast.fail('失败:巡查描述不能为空');
      }
      if (this.selectMsg.description === '') {
        return this.$toast.fail('失败:请先输入问题描述');
      }
      // 问题校验
      if (this.submitData.problemList.length != 0 && this.problemSubmit) {
        for (var i = 0; i < this.submitData.problemList.length; i++) {
          if (!this.submitData.problemList[i].type) {
            return this.$toast.fail('失败:请选择问题类型')
          }
          if (!this.submitData.problemList[i].des) {
            return this.$toast.fail('失败:请输入问题描述')
          }
        }
      }
      let problemList = this.submitData.problemList
      let problems = []
      for (let i in problemList) {
        let problem = problemList[i]
        let problemObject = {
          type: problem.type,
          id: problem.id || undefined,
          des: problem.des,
          files: problem.files,
          isRelation: problem.isRelation
        }
        problems.push(problemObject)
      }
      let ids = this.problemVisitIds !== '' ? this.problemVisitIds.split(',') : ''
      this.submitData.content = this.selectMsg.description
      this.loading = true
      this.$http({
        url: this.$http.adornUrl(`/wxapp/inspection/${this.submitData.id ? 'update' : 'save'}`),
        method: 'post',
        data: {
          id: this.submitData.id,
          //隔间
          room: this.submitData.rentHouseCount,
          //巡查地点id
          addrId: this.submitData.addrId,
          //楼幢id
          buildingId: this.submitData.buildingId,
          //详细地址
          address: this.submitData.address,
          detailedAddress: this.submitData.detailedAddressValue,
          //巡查地点类型
          addrType: this.submitData.addrType,
          //巡查类型
          inspectionType: this.submitData.inspectionType,
          //巡查描述
          inspectionDes: this.submitData.description,
          //巡查时间
          inspectionTime: formatterDateTimeWithOutSec(this.submitData.inspectionTime),
          //巡查图片
          inspectionfileMessages: this.submitData.newFileMessages,
          //修改图片组
          newFileMessages: this.submitData.newFileMessages,
          //问题集合
          problemList: !this.submitData.id ? problems : [],
          //社区
          community: this.submitData.community,
          //小区
          subarea: this.submitData.subarea,
          //删除图片的id
          sourceType: this.submitData.sourceType,
          deleteFiles: this.submitData.deleteFiles,
          problemIds: this.submitData.id ? this.problemInfoIds : ids.slice(0, ids.length - 1).toString(),
        }
      }).then(({data}) => {
        if (data.code == 0) {
          this.$toast.success({
            message: `${this.submitData.id ? '修改成功' : '新增成功'}`,
            duration: 3000,
            onOpened: () => {
              setTimeout(() => {
                this.$router.go(-1)
              }, 1500)
            }
          })
        } else {
          this.loading = false
          this.$toast.fail(data.msg);
        }
      })
      this.loading = false
    },
    // 巡查地点选择
    addrConfirm(value) {
      if (value) {
        this.selectMsg.addrTypeStr = value.label
        this.submitData.addrType = value.value
      }
      this.addrShow = false
    },
    sourceTypeConfirm(value) {
      if (value) {
        this.selectMsg.sourceTypeStr = value.label
        this.submitData.sourceType = value.value
      }
      this.sourceTypeShow = false
    },
    // 建筑类别选择
    buildingTypeConfirm(value) {
      if (value) {
        this.selectMsg.buildingTypeStr = value.label
        this.submitData.buildingType = value.value
        if (value.value == 1 && this.submitData.community !== '') {
          this.getBuildingList(this.submitData.community)
        } else {
          this.getPublicBuildingList(value.value, this.submitData.community)
        }
        if (this.submitData.buildingType !== '' || (this.submitData.detailedAddressValue.length > 0 || this.submitData.addrId !== '') && (this.submitData.addrType == 1 || this.submitData.addrType == 2)) {
          this.getPatrolProblem()
        }
      }
      this.buildingTypeShow = false
    },
    // 房屋选择
    buildingFinish(value) {
      if (value) {
        let options = value.selectedOptions
        let right = options[options.length-1]
          let orgId = options[options.length-1].value
          if(right.depth !== 4 && right.parameter == null) {
            getBelongCommunity(orgId, value.tabIndex + 1, (communityList) => {
              options[value.tabIndex].children = communityList
            })
          } else if(options && options.length > 1 && right.parameter !== null){
              this.submitData.subarea = options[0].value+""
              this.submitData.buildingId = options[1].value+""

              if(right && (right.depth ==2 ||right.depth ==4)){
                this.submitData.detailedAddressValue = options.map(item => {
                  return item.value+""
                })
                this.selectMsg.buildingStr = ''
                value.selectedOptions.map(item => {
                  this.selectMsg.buildingStr += item.label
                })
                this.submitData.address = this.selectMsg.buildingStr
              }

              if(right && right.depth==2 && right.parameter && right.parameter>0){
                this.submitData.addrId = right.parameter+""
                this.submitData.building = right.parameter+""
              }else if(right && right.depth==4){
                this.submitData.addrId = right.value+""
                this.submitData.building =right.value+""
              }else{
                this.submitData.subarea=""
                this.submitData.buildingId=""
                this.submitData.addrId=""
                this.submitData.building=""
                this.submitData.detailedAddressValue=[]
                this.selectMsg.buildingStr=""
                this.selectMsg.address=""
              }
            this.buildingShow = false
        }else{
          this.submitData.subarea=""
          this.submitData.buildingId=""
          this.submitData.addrId=""
          this.submitData.building=""
          this.submitData.detailedAddressValue=[]
          this.selectMsg.buildingStr=""
          this.selectMsg.address=""
        }
        if (this.submitData.buildingType !== '' || (this.submitData.detailedAddressValue.length > 0 || this.submitData.addrId !== '') && (this.submitData.addrType == 1 || this.submitData.addrType == 2)) {
          this.getPatrolProblem()
        }
      }
    },
    // 公共楼幢选择
    publicBuildingConfirm(value) {
      if (value) {
        this.selectMsg.publicBuildingStr = value.name
        this.submitData.addrId = value.id
        this.submitData.buildingId = value.id
        this.submitData.detailedAddressValue = [value.id]
        this.submitData.address = value.name
        if (this.submitData.buildingType !== '' || (this.submitData.detailedAddressValue.length > 0 || this.submitData.addrId !== '') && (this.submitData.addrType == 1 || this.submitData.addrType == 2)) {
          this.getPatrolProblem()
        }
      }
      this.publicBuildingShow = false
    },
    communityConfirm(value) {
      if (value) {
        let community = value.value
        this.selectMsg.communityStr = value.label
        this.submitData.community = community
        this.getSubareaList(community)
        this.getBuildingList(community)
        this.getPublicBuildingList(this.submitData.buildingType, community)
        this.getHasProblem()
      }
      this.communityShow = false
    },
    // 所在小区选择
    subareaConfirm(value) {
      if (value) {
        this.selectMsg.subareaStr = value.name
        this.submitData.subarea = value.id
      }
      this.subareaShow = false
    },
    // 巡查类型选择
    inspectionTypeFinish(value) {
      if (value) {
        this.selectMsg.inspectionTypeStr = value.selectedOptions[0].label
        this.submitData.inspectionType = value.value
      }
      this.inspectionTypeShow = false
    },
    // 日期选项格式化
    formatDate(type, val) {
      if (type === 'year') {
        return val + '年'
      }
      if (type === 'month') {
        return val + '月'
      }
      if (type === 'day') {
        return val + '日'
      }
      if (type === 'hour') {
        return val + '时'
      }
      if (type === 'minute') {
        return val + '分'
      }
      return val
    },
    timeConfirm(value) {
      if (value) {
        this.selectMsg.inspectionTime = formatterDateTimeWithOutSec(value)
        this.submitData.inspectionTime = formatterDateTimeWithOutSec(value)
      }
      this.inspectionTimeShow = false
    },
    // 选择问题类型
    checkProblemType(index) {
      this.problemTypeShow = true
      this.i = index
    },
    // 问题类型选择
    problemTypeFinish(value) {
      if (value) {
        this.submitData.problemList[this.i].typeTitle = value.selectedOptions[value.selectedOptions.length - 1].label
        this.submitData.problemList[this.i].type = value.value
      }
      this.problemTypeShow = false
    },
    // 选择场所
    selectPlace() {
      if (this.submitData.community == '') {
        this.$toast.fail('失败:请先选择所在社区');
      } else {
        this.visible = false
        this.placeListVisible = true
      }
    },
    // 选择完场所
    checkPlace(msg) {
      this.placeListVisible = false
      this.visible = true
      this.submitData.addrId = msg.addrId
      this.submitData.buildingId = 0
      this.submitData.address = msg.address
      this.selectMsg.placeStr = msg.placeTitle
      if (this.submitData.buildingType !== '' || (this.submitData.detailedAddressValue.length > 0 || this.submitData.addrId !== '') && (this.submitData.addrType == 1 || this.submitData.addrType == 2)) {
        this.getPatrolProblem()
      }
    },
    // 是否上报问题
    problemSubmitShow() {
      if (this.problemSubmit && this.submitData.problemList.length === 0) {
        this.addProblem()
      }
    },
    // 新增问题
    addProblem() {
      var problem = {
        type: 0,
        typeTitle: '请选择',
        des: '',
        problemUrls: [],
        files: [],
        opened: true,
        isRelation: false
      }
      this.submitData.problemList.push(problem)
    },
    // 跳转问题列表
    toProblem(){
      if (this.problemIds.length > 0) {
        localStorage.setItem('isRefresh', 1)
        this.$router.push({path: '/problem', query: {ids: this.problemIds.toString()}})
      }
    },
    // 删除问题
    deleteProblem(i) {
      this.submitData.problemList.splice(i, 1)
      if (this.submitData.problemList.length === 0) {
        this.problemSubmit = false
      }
    },
    formTasks() {
      this.$http({
        url: this.$http.adornUrl(''),
        method: 'post',
        data: this.$http.adornData({
          dictId: this.$route.query.dictId,
          targetId: this.$route.query.targetId,
          address: this.$route.query.address,
          objectType: this.$route.query.objectType,
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.submitData.address = data.entity.address
          this.submitData.buildingId = data.entity.buildingId
          this.submitData.addrId = data.entity.addrId
          this.submitData.addrType = data.entity.addrType
          this.selectMsg.addrTypeStr = data.entity.addrType === 1 ? '楼幢' : '场所'
          this.submitData.inspectionType = data.entity.inspectionType
          this.selectMsg.inspectionTypeStr = data.entity.inspectionTypeStr
          this.submitData.address = data.entity.address
          this.submitData.subarea = data.entity.subarea
          this.isTasks = true
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    getIdsList(ids, index, arr, list) {
      let i = arr.findIndex(item => ids[index] == item.value)
      if (i !== -1) {
        list.push(arr[i])
      }
      if (arr[i].children && ids[index++]) {
        this.getIdsList(ids, index++, arr[i].children, list)
      }
    },
  },
  created() {
    this.submitData.id = this.$route.query.id || ''
    document.querySelector('html').style.backgroundColor = '#fff'
    this.submitData.orgId = this.$orgId
    this.submitData.userId = this.$globalData.userInfo.userId
    if (this.$route.query.tasks) {
      this.formTasks()
    }
    if (this.$route.query.ids) {
      this.addrConfirm({label: "楼幢", value: "1"})
      this.buildingTypeConfirm({label: "住宅楼幢", value: "1"})
    }
    this.init()
  },
  beforeDestroy() {
    this.$store.state.problemVisitIds = ''
    document.querySelector('html').style.backgroundColor = ''
  }
}
</script>
<style scoped lang="scss">
.left >>> .van-field__control {
  text-align: left !important;
}
.left >>> .van-icon {
  position: absolute;
  top: 0;
  right: 0;
}
.check {
  color: #3F8BFC !important;

  .van-button {
    width: 92px;
    height: 52px;
    font-size: 24px;
    border-color: #4E7CF5;
    border-radius: 12px;
  }

  ::v-deep .van-button--normal {
    padding: 0;
  }
}
.tab {
  ::v-deep .van-tabs__line {
    opacity: 0;
  }
  ::v-deep .van-tabs__wrap {
    height: 0;
  }
}
</style>
